<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar :has-right-visible="false">
      Dashboard de atendentes
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-widget class="tile is-child" icon="account" :number="getAttendantsDashboard.AttendantsCount"
          label="Total" />
        <card-widget class="tile is-child" icon="account" :number="getAttendantsDashboard.ActiveCount" label="Ativos" />
        <card-widget class="tile is-child" icon="account" :number="getAttendantsDashboard.InactiveCount"
          label="Inativos" />
      </tiles>
      <tiles>
        <card-widget class="tile is-child" type="is-success" icon="account" :number="getAttendantsDashboard.OnlineCount"
          label="Online" :clickable="true" @cardClick="displayAttendants('online')" />
        <card-widget class="tile is-child" type="is-warning" icon="account"
          :number="getAttendantsDashboard.InvisibleCount" label="Ausentes" :clickable="true"
          @cardClick="displayAttendants('invisible')" />
        <card-widget class="tile is-child" type="is-danger" icon="account" :number="getAttendantsDashboard.OfflineCount"
          label="Offline" :clickable="true" @cardClick="displayAttendants('offline')" />
      </tiles>

      <card-component title="Atendentes por departamento" class="has-table has-mobile-sort-spaced"
        :showIcon="{ show: 'message', hidde: 'message-off' }" :toggleIcon="showTktColumns"
        iconText="Exibir informações de tickets" @show-icon-click="toggleShowTktColumns">
        <b-table v-if="departmentsAttendants.length > 0" :data="departmentsAttendants" :bordered="true" :striped="true"
          :mobile-cards="true" scrollable paginated per-page="10">
          <b-table-column field="department" label="Departamento" v-slot="props">
            {{ props.row.name }}
          </b-table-column>

          <b-table-column field="allTicketsPending" label="Pendentes" v-slot="props" :visible="showTktColumns" numeric
            header-class="tkt-columns-header" cell-class="tkt-columns-cell">
            <p @click="showTickets({ dpt: props.row, tkt_status: 'ABS', attStatus: null })">
              {{ props.row.pendingTktCount }}
            </p>
          </b-table-column>

          <b-table-column field="attendants" label="Total" v-slot="props" numeric>
            {{ props.row.totalAttendantsCount }}
          </b-table-column>

          <b-table-column field="allTickets" label="Tickets" v-slot="props" :visible="showTktColumns" numeric
            header-class="tkt-columns-header" cell-class="tkt-columns-cell">
            <p @click="showTickets({ dpt: props.row, tkt_status: 'ABA', attStatus: null })">
              {{ props.row.openTktCount }}
            </p>
          </b-table-column>

          <b-table-column field="active" label="Ativos" v-slot="props" numeric>
            {{ props.row.activeCount }}
          </b-table-column>

          <b-table-column field="allTicketsActive" label="Tickets" v-slot="props" :visible="showTktColumns" numeric
            header-class="tkt-columns-header" cell-class="tkt-columns-cell">
            <p @click="showTickets({ dpt: props.row, tkt_status: 'ABAA', attStatus: null })">
              {{ props.row.activeTktCount }}
            </p>
          </b-table-column>

          <b-table-column field="inactive" label="Inativos" v-slot="props" numeric>
            {{ props.row.inactiveCount }}
          </b-table-column>

          <b-table-column field="allTicketsInactive" label="Tickets" v-slot="props" :visible="showTktColumns" numeric
            header-class="tkt-columns-header" cell-class="tkt-columns-cell">
            <p @click="showTickets({ dpt: props.row, tkt_status: 'ABAI', attStatus: null })">
              {{ props.row.inactiveTktCount }}
            </p>
          </b-table-column>

          <b-table-column field="online" label="Online" v-slot="props" numeric header-class="online-column-header">
            {{ props.row.onlineCount }}
          </b-table-column>

          <b-table-column field="allTicketsOnline" label="Tickets" v-slot="props" :visible="showTktColumns" numeric
            header-class="tkt-columns-header online-tkt-header" cell-class="tkt-columns-cell">
            <p @click="showTickets({ dpt: props.row, tkt_status: 'ABA', attStatus: 'online' })">
              {{ props.row.onlineTktCount }}
            </p>
          </b-table-column>

          <b-table-column field="invisible" label="Ausentes" v-slot="props" numeric
            header-class="invisible-column-header">
            {{ props.row.invisibleCount }}
          </b-table-column>

          <b-table-column field="allTicketsInvisible" label="Tickets" v-slot="props" :visible="showTktColumns" numeric
            header-class="tkt-columns-header invisible-tkt-header" cell-class="tkt-columns-cell">
            <p @click="showTickets({ dpt: props.row, tkt_status: 'ABA', attStatus: 'invisible' })">
              {{ props.row.invisibleTktCount }}
            </p>
          </b-table-column>

          <b-table-column field="offline" label="Offline" v-slot="props" numeric header-class="offline-column-header">
            {{ props.row.offlineCount }}
          </b-table-column>

          <b-table-column field="allTicketsOffline" label="Tickets" v-slot="props" :visible="showTktColumns" numeric
            header-class="tkt-columns-header offline-tkt-header" cell-class="tkt-columns-cell">
            <p @click="showTickets({ dpt: props.row, tkt_status: 'ABA', attStatus: 'offline' })">
              {{ props.row.offlineTktCount }}
            </p>
          </b-table-column>

        </b-table>
        <div v-else class="empty-table">
          <p>Você ainda não possui departamentos cadastrados</p>
        </div>
      </card-component>

      <card-component title="Atendentes" class="has-table has-mobile-sort-spaced">
        <b-table v-if="attendants.length > 0" :data="attendants" :bordered="true" :striped="true" :mobile-cards="true"
          scrollable paginated per-page="10">
          <b-table-column field="name" label="Nome" v-slot="props" searchable>
            {{ props.row.name }}
          </b-table-column>

          <b-table-column field="isActive" label="Ativo?" v-slot="props">
            <IsActiveTag :active="props.row.isActive" />
          </b-table-column>

          <b-table-column field="status.name" label="Status" v-slot="props" searchable>
            <p :style="{ color: props.row.status.color }">
              {{ props.row.status.name }}
            </p>
          </b-table-column>

          <b-table-column field="departmentName" label="Departamentos" v-slot="props">
            <MultipleTags :v-if="props.row.departments" :tags="getAttendantDepartments(props.row.departments)" />
          </b-table-column>

          <b-table-column field="supervisor_all_departments" width="200" label="Supervisor todos os depart."
            v-slot="props">
            {{ props.row.supervisor_all_departments ? 'Sim' : 'Não' }}
          </b-table-column>

          <b-table-column field="custom_code" width="200" label="Código customizado" v-slot="props" searchable>
            {{ props.row.custom_code }}
          </b-table-column>
        </b-table>
        <div v-else class="empty-table">
          <p>Você ainda não possui atendentes cadastrados</p>
        </div>
      </card-component>
    </section>

    <ModalAttendantsList :isComponentModalActive="isModalAttendantsListOpen" :title="modalAttendantsListTitle"
      :attendants="attendantsByStatus" @showAttendantTickets="showAttendantTickets" @closeModal="closeModal" />

    <ModalAttendantTickets :isComponentModalActive="isModalAttendantTicketsOpen" :title="modalAttendantTicketsTitle"
      :tickets="attendantTickets" :type="ticketBy" @updateTickets="updateTickets" @closeModal="closeModalAttTkt" />
  </div>
</template>

<script>
// @ is an alias to /src
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import CardWidget from '@/components/CardWidget'
import CardComponent from '@/components/CardComponent'
import IsActiveTag from '../components/commons/IsActiveTag.vue'
import MultipleTags from '../components/commons/MultipleTags.vue'
import ModalAttendantsList from '../components/attendantsDashboard/ModalAttendantsList.vue'
import ModalAttendantTickets from '../components/attendantsDashboard/ModalAttendantTickets.vue'
import { mapActions, mapGetters } from 'vuex'
import 'vue-advanced-chat/dist/vue-advanced-chat.css'
import mixin from '../utils/mixins'

export default {
  name: 'AttendantsDashboard',
  components: {
    CardComponent,
    CardWidget,
    Tiles,
    HeroBar,
    TitleBar,
    IsActiveTag,
    MultipleTags,
    ModalAttendantsList,
    ModalAttendantTickets
  },
  mixins: [mixin],
  data () {
    return {
      // modal open
      isModalAttendantsListOpen: false,
      isModalAttendantTicketsOpen: false,

      // modal title
      modalAttendantsListTitle: '',
      modalAttendantTicketsTitle: '',

      // tickets modal type
      ticketBy: '',

      // attendants table data
      attendants: [],

      // modal attendants list data
      attendantsByStatus: [],
      selectedStatus: '',

      // modal attendants tickets
      attendantTickets: [],
      selectedAttendant: '',
      selectedDepartment: null,

      // departments table data
      departmentsAttendants: [],
      showTktColumns: true,

      // counts
      attendantsCount: {},
    }
  },
  computed: {
    ...mapGetters([
      'getIsAdmin',
      'getIsSupervisor',
      'allAttendants',
      'getAttendantsDashboard',
      'getDepartmentsAttendantsDashboard',
      'getAttendantsTicketsCount',
      'getAttendantsTickets',
      'getToken'
    ]),
    titleStack () {
      return ['Admin', 'Atendentes']
    }
  },
  async mounted () {
    this.fetchAll()
  },
  watch: {
    getDepartmentsAttendantsDashboard () {
      this.departmentsAttendants = JSON.parse(JSON.stringify(this.getDepartmentsAttendantsDashboard))
    },
    getAttendantsDashboard () {
      this.attendants = JSON.parse(JSON.stringify(this.getAttendantsDashboard.attendants))
      this.attendants = this.attendants.map(att => {
        att.status = {
          key: att.status,
          name: att.status === 'online'
            ? 'Online'
            : att.status === 'invisible'
              ? 'Ausente'
              : 'Offline',
          color: att.status === 'online'
            ? 'green'
            : att.status === 'invisible'
              ? 'orange'
              : 'red'
        }

        return att
      })
    }

  },
  methods: {
    ...mapActions([
      'fetchDepartmentsAttendants',
      'fetchAttendantsTicketsCount',
      'fetchAttendantsTickets',
      'fetchAttendantsDashboard'
    ]),
    async fetchAll () {
      if (this.getIsAdmin || this.getIsSupervisor) {
        await this.fetchAttendantsDashboard()
        this.fetchDepartmentsAttendants()
        this.departmentsAttendants = JSON.parse(JSON.stringify(this.getDepartmentsAttendantsDashboard))
        this.attendantsCount = this.getAttendantsDashboard.attendantsCount
        this.attendants = JSON.parse(JSON.stringify(this.getAttendantsDashboard.attendants))
        this.attendants = this.attendants.map(att => {
          att.status = {
            key: att.status,
            name: att.status === 'online'
              ? 'Online'
              : att.status === 'invisible'
                ? 'Ausente'
                : 'Offline',
            color: att.status === 'online'
              ? 'green'
              : att.status === 'invisible'
                ? 'orange'
                : 'red'
          }

          return att
        })
      }
    },
    getAttendantDepartments (dpts) {
      return dpts.map(el => ({ title: el.name, id: el.id, supervisor: el.supervisor }))
    },
    async displayAttendants (status) {
      this.selectedStatus = status
      await this.fetchAttendantsTicketsCount(status)
      this.attendantsByStatus = this.getAttendantsTicketsCount

      let title = 'Atendentes '
      title += status === 'online' ? 'Online' : status === 'offline' ? 'Offline' : status === 'invisible' ? 'Ausentes' : ''
      this.isModalAttendantsListOpen = true
      this.modalAttendantsListTitle = title
    },
    async showAttendantTickets (att) {
      this.selectedAttendant = att.id
      await this.fetchAttendantsTickets({
        attendant_id: att.id
      })

      this.attendantTickets = JSON.parse(JSON.stringify(this.getAttendantsTickets))
      this.modalAttendantTicketsTitle = att.name + ' - Tickets'
      this.ticketBy = 'att'
      this.isModalAttendantTicketsOpen = true
    },
    async showTickets (data) {
      this.selectedDepartment = data
      await this.fetchAttendantsTickets({
        attendant_status: data.attStatus,
        tkt_status: data.tkt_status,
        department_id: data.dpt.id
      })

      this.attendantTickets = JSON.parse(JSON.stringify(this.getAttendantsTickets))
      let title = ''

      if (data.tkt_status === 'ABAA') {
        title = data.dpt.name + ' - Tickets Abertos com Atendentes Ativos'
      } else if (data.tkt_status === 'ABAI') {
        title = data.dpt.name + ' - Tickets Abertos com Atendentes Inativos'
      } else if (data.tkt_status === 'ABS') {
        title = data.dpt.name + ' - Tickets Abertos Sem Atendente'
      } else if (data.tkt_status === 'ABA') {
        title = data.dpt.name + ' - Tickets Abertos Com Atendente'
      }

      if (data.attStatus === 'online') {
        title = data.dpt.name + ' - Tickets Abertos com Atendentes Online'
      } else if (data.attStatus === 'offline') {
        title = data.dpt.name + ' - Tickets Abertos com Atendentes Offline'
      } else if (data.attStatus === 'invisible') {
        title = data.dpt.name + ' - Tickets Abertos com Atendentes Ausentes'
      }

      this.ticketBy = 'dpt'
      this.modalAttendantTicketsTitle = title
      this.isModalAttendantTicketsOpen = true
    },
    async updateTickets (tktBy) {
      if (tktBy === 'att') {
        // recarregar a modal de tickets
        await this.fetchAttendantsTickets({
          attendant_id: this.selectedAttendant
        })
        this.attendantTickets = JSON.parse(JSON.stringify(this.getAttendantsTickets))

        // recarregar a modal de atendentes
        await this.fetchAttendantsTicketsCount(this.selectedStatus)
        this.attendantsByStatus = this.getAttendantsTicketsCount
      } else if (tktBy === 'dpt') {
        // recarregar a modal de tickets
        await this.fetchAttendantsTickets({
          attendant_status: this.selectedDepartment.attStatus,
          tkt_status: this.selectedDepartment.tkt_status,
          department_id: this.selectedDepartment.dpt.id
        })
        this.attendantTickets = JSON.parse(JSON.stringify(this.getAttendantsTickets))

        // recarregar a tabela de departamentos
        this.fetchDepartmentsAttendants()
        this.departmentsAttendants = JSON.parse(JSON.stringify(this.getDepartmentsAttendantsDashboard))
      }
    },
    closeModal () {
      this.selectedStatus = ''
      this.isModalAttendantsListOpen = false
      this.modalAttendantsListTitle = ''
    },
    closeModalAttTkt () {
      this.selectedAttendant = ''
      this.selectedDepartment = null
      this.isModalAttendantTicketsOpen = false
      this.attendantTickets = []
    },
    toggleShowTktColumns () {
      this.showTktColumns = !this.showTktColumns
    }
  },
  created () {
    this.$socket.on('attendant_status_changed', () => {
        this.fetchAll()
    })
  },
  beforeDestroy () {
    this.$socket.removeAllListeners('attendant_status_changed')
  }
}
</script>

<style>
.empty-table p {
  padding: 12px 16px;
}

/* TICKETS COLUMNS */

.tkt-columns-header {
  background-color: hsl(0deg, 0%, 97%) !important;
  color: hsl(0deg, 0%, 35%) !important;
  border: hsl(0deg, 0%, 93%) !important;
  box-shadow: inset 2px 0px 2px hsl(0deg, 0%, 93%);
}

.tkt-columns-cell {
  background-color: hsl(0deg, 0%, 97%) !important;
  box-shadow: inset 2px 0px 2px hsl(0deg, 0%, 93%);
  border: hsl(0deg, 0%, 93%) !important;
  color: #006d5f;
  font-weight: 600;
}

.tkt-columns-cell p {
  cursor: pointer;
}

/* OFFLINE COLUMNS */

.offline-column-header,
.offline-tkt-header {
  color: hsl(348deg, 86%, 50%) !important;
}

/* ONLINE COLUMNS */

.online-column-header,
.online-tkt-header {
  color: hsl(153deg, 53%, 40%) !important;
}

/* INVISIBLE COLUMNS */

.invisible-column-header,
.invisible-tkt-header {
  color: hsl(44deg, 100%, 60%) !important;
}
</style>
