<template>
  <div>
    <div v-if="allAttendants.length > 0">
      <b-table :data="allAttendants" :bordered="false" :striped="true" :narrowed="false" :hoverable="true"
        :loading="false" :focusable="false" :mobile-cards="true" scrollable paginated per-page="10">

        <b-table-column field="name" label="Nome" v-slot="props">
          {{ props.row.name }}
        </b-table-column>

        <b-table-column field="departmentName" label="Departamentos" v-slot="props">
          <MultipleTags :v-if="props.row.attendants_departments"
            :tags="getAttendantDepartments(props.row.attendants_departments)" />
        </b-table-column>

        <b-table-column field="supervisor_all_departments" width="200" label="Supervisor todos os depart." v-slot="props">
          {{ props.row.supervisor_all_departments ? 'Sim' : 'Não' }}
        </b-table-column>

        <b-table-column field="custom_code" width="200" label="Código customizado" v-slot="props">
          {{ props.row.custom_code }}
        </b-table-column>

        <b-table-column field="is_active" label="Ativo?" v-slot="props">
          <IsActiveTag :active="props.row.isActive" />
        </b-table-column>

        <b-table-column field="ticketsCount" label="Tickets" v-slot="props">
          <p class="cell-clickable count" v-if="props.row.ticketsCount > 0" @click="showTickets(props.row)">
            {{ props.row.ticketsCount }}
          </p>
          <p class="count" v-else>
            {{ props.row.ticketsCount }}
          </p>
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>

import mixin from '../../utils/mixins'
import IsActiveTag from '../commons/IsActiveTag.vue'
import MultipleTags from '../commons/MultipleTags.vue'

export default {
  name: 'AttendantsList',
  props: {
    attendants: {
      type: Array,
      required: true
    }
  },
  mixins: [mixin],
  components: {
    IsActiveTag,
    MultipleTags
  },
  computed: {
    allAttendants: {
      get () {
        return this.attendants
      }
    }
  },
  methods: {
    getAttendantDepartments (dpts) {
      return dpts.map(el => ({ title: el.department.name, id: el.department.id, supervisor: el.supervisor }))
    },
    async showTickets (att) {
      this.$emit('showAttendantTickets', att)
    }
  }
}
</script>

<style lang="scss">
p.cell-clickable {
  cursor: pointer;
  width: fit-content;
}

p.count {
  color: #00A48D;
  font-weight: 600;
}
</style>
