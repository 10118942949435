import { render, staticRenderFns } from "./MultipleTags.vue?vue&type=template&id=79092576&"
import script from "./MultipleTags.vue?vue&type=script&lang=js&"
export * from "./MultipleTags.vue?vue&type=script&lang=js&"
import style0 from "./MultipleTags.vue?vue&type=style&index=0&id=79092576&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports