<template>
  <div>
    <section>
      <b-modal :active="isComponentModalActive" @close="closeModal">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ title }} (Clique nas quantidades de tickets para mais detalhes)</p>
          </header>
          <section class="modal-card-body">
            <AttendantsList
              v-if="attendants.length > 0"
              :attendants="attendants"
              @showAttendantTickets="showAttendantTickets"
            />
            <div v-else>
              <p>No momento não há {{ title.toLowerCase() }}!</p>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="closeModal">
              Fechar
            </button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import AttendantsList from './AttendantsList.vue'

export default {
  name: 'ModalAttendantsList',
  components: {
    AttendantsList
  },
  props: {
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    attendants: {
      type: Array,
      required: true
    }
  },
  methods: {
    showAttendantTickets (att) {
      this.$emit('showAttendantTickets', att)
    },
    closeModal () {
      this.$emit('closeModal')
    }
  }
}
</script>

<style scoped>
/* .contact-card {
  display: table;
}

.contact-card .content {
  width: 600px;
  display: table-cell;
}

.contact-card .add-contact {
  display: table-cell;
} */
</style>
