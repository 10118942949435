<template>
  <div>
    <section>
      <b-modal :active="isComponentModalActive" @close="closeModal">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ title }}</p>
          </header>
          <section class="modal-card-body">
            <b-table
              :data="tickets"
              :bordered="true"
              :striped="true"
              paginated
              :mobile-cards="true"
              v-if="tickets.length > 0"
              sort-icon="arrow-up"
              sort-icon-size="is-small"
              default-sort="id"
              aria-next-label="Próxima página"
              aria-previous-label="Página anterior"
              aria-page-label="Página"
              aria-current-label="Página atual"
              scrollable
              :show-detail-icon="true"
            >
              <b-table-column sortable field="id" label="ID" v-slot="props">
                {{ props.row.id }}
              </b-table-column>

              <b-table-column field="ww_phone" label="Origem" v-slot="props">
                {{ props.row.ww_phone }}
              </b-table-column>

              <b-table-column field="client_name" label="Nome no whats" v-slot="props">
                {{ props.row.client_name }}
              </b-table-column>

              <b-table-column field="contact.name" label="Contato" v-slot="props">
                {{
                  props.row.contact
                    ? `${props.row.contact.id} - ${props.row.contact.name}`
                    : ""
                }}
              </b-table-column>

              <b-table-column :visible="type === 'att'" field="department_id" label="Departamento" v-slot="props">
                {{ props.row.department ? `${props.row.department.name}` : '' }}
              </b-table-column>

              <b-table-column :visible="type === 'dpt'" field="attendant_id" label="Atendente" v-slot="props">
                {{ props.row.attendant ? `${props.row.attendant.name}` : '' }}
              </b-table-column>

              <b-table-column field="client_phone" label="Telefone" v-slot="props">
                {{ props.row.client_phone }}
              </b-table-column>

              <b-table-column sortable field="client_phone" label="Tipo" v-slot="props">
                <IsGroupTag :is_group="!!props.row.is_group" />
              </b-table-column>

              <b-table-column sortable field="is_open" label="Status" v-slot="props">
                {{ props.row.is_open ? "Aberto" : "Fechado" }}
              </b-table-column>

              <b-table-column field="created_at" label="Data de abertura" v-slot="props">
                {{ formatDateTime(props.row.created_at) }}
              </b-table-column>

              <b-table-column field="actions" label="Mensagens" v-slot="props">
                <MessagesTicketButton :ticket="props.row" />
              </b-table-column>

              <b-table-column field="actions" label="Transferir" v-slot="props" v-if="getIsSupervisorAllDepartments">
                <TransferTicketButton :ticket="props.row" v-on:reloadTickets="updateTickets" />
              </b-table-column>

              <b-table-column field="actions" label="Fechar" v-slot="props" v-if="getIsSupervisorAllDepartments">
                <CloseTicketButton :ticket="props.row" v-on:reloadTickets="updateTickets" />
              </b-table-column>

              <template #footer>
                <div class="has-text-left">
                  Quantidade de registros {{ tickets.length }}
                </div>
              </template>
            </b-table>
            <div v-else>
              <p v-if="type === 'att'">O atendente não possui tickets abertos!</p>
              <p v-if="type === 'dpt'">O departamento não possui tickets abertos!</p>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="closeModal">
              Fechar
            </button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import IsGroupTag from '@/components/commons/IsGroupTag.vue'
import MessagesTicketButton from '@/components/tickets/MessagesTicketButton'
import CloseTicketButton from '@/components/tickets/CloseTicketButton'
import TransferTicketButton from '@/components/tickets/TransferTicketButton'
import { mapGetters } from 'vuex'
import mixin from '../../utils/mixins'

export default {
  name: 'ModalAttendantTickets',
  components: {
    IsGroupTag,
    MessagesTicketButton,
    CloseTicketButton,
    TransferTicketButton
  },
  mixins: [mixin],
  props: {
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    tickets: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters(['getIsSupervisorAllDepartments'])
  },
  methods: {
    showAttendantTickets (data) {
      this.$emit('showAttendantTickets', data)
    },
    updateTickets (data) {
      this.$emit('updateTickets', this.type)
    },
    closeModal () {
      this.$emit('closeModal')
    }
  }
}
</script>

<style scoped>
/* .contact-card {
  display: table;
}

.contact-card .content {
  width: 600px;
  display: table-cell;
}

.contact-card .add-contact {
  display: table-cell;
} */
</style>
