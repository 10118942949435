<template>
    <div class="multiple-tags-container tooltip">
      <span v-for="tag in shownTags" v-bind:key="tag.title" class="custom-tag">
        {{ tag.title }}
      </span>
      {{ hiddenTags }}
      <span class="tooltiptext">
        <ul>
          <li v-for="tag in tags" v-bind:key="tag.title">
            {{ tag.title }} {{ tag.supervisor ? '(Supervisor)' : '' }}
          </li>
        </ul>
      </span>
    </div>
  </template>

<script>
export default {
  name: 'MultipleTags',
  props: {
    tags: {
      type: Array,
      required: true
    },
    show: {
      type: Number,
      required: false,
      default: 1
    }
  },
  data () {
    return {
      hiddenTags: ''
    }
  },
  computed: {
    shownTags: {
      get () {
        return this.tags.slice(0, this.show)
      }
    }
  },
  created () {
    this.getHidden()
  },
  methods: {
    getHidden () {
      const hidden = this.tags.length - this.shownTags.length

      if (hidden) {
        this.hiddenTags = hidden < 1 ? '' : `+${hidden}`
      }
    }
  }
}
</script>

<style >
  .table td {
    height: 100%;
    position: relative;
    vertical-align: middle !important;
  }

  .multiple-tags-container{
    color: #515353 !important;
    font-weight: 100;
    height: 100%;
  }

  .custom-tag {
    text-align: center;
    background-color: #00a48e50;
    padding: 5px 10px;
    margin: 0px 5px 0px 0px;
    border-radius: 999px;
    white-space: nowrap;
  }

  .tooltip {
    position: relative;
    display: inline-block;
    color: #006080;
    font-size: 14px;
  }

  .tooltip:hover .tooltiptext{
    visibility: visible;
    opacity: 1;
  }

  .tooltiptext {
    visibility: hidden;
    position: absolute;
    width: auto;
    background-color: rgba(85, 85, 85, 0.85);
    color: #fff;
    text-align: left;
    font-size: 14px;
    padding: 5px 5px 5px 10px;
    border-radius: 6px;
    z-index: 9999;
    opacity: 0;
    transition: opacity .6s;
    white-space: nowrap;
  }

  .tooltip-right {
    top: -5px;
    left: 125%;
  }

  .tooltip-right::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%; /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
  }
</style>
